export const ICONS = {
  'campaigns': 'i-ph-megaphone',
  'senderids': 'i-ph-identification-card',
  'offers': 'i-ph-basket',
  'texts': 'i-ph-chat-centered-text',
  'marketing': 'i-ph-megaphone',
  'sends': 'i-ph-paper-plane-right',
  'overview': 'i-ph-align-left',
  'routes': 'i-ph-path',
  'logs': 'i-ph-notebook',
  'help': 'i-ph-lifebuoy',
  'create': 'i-heroicons-plus',
  'segments': 'i-ph-users-four',
  'simulate': 'i-ph-play',
  'close': 'i-ph-x',
  'trash': 'i-ph-trash',
  'companies': 'i-ph-building',
  'endpoints': 'i-ph-link',
  'integrations': 'i-ph-cube',
  'stop': 'i-ph-stop',
  'events': 'i-ph-bell-simple',
  'settings': 'i-heroicons-cog',
  'people': 'i-heroicons-users',
  'numbers': 'i-heroicons-phone',
  'tools': 'i-heroicons-wrench',
  'play': 'i-heroicons-play',
  'pause': 'i-heroicons-pause',
}

export const DOCS = {
  'campaign/senderids': 'https://comm.com/docs/campaigns',
  'campaign/texts': 'https://comm.com/docs/campaigns',
  'campaign/offers': 'https://comm.com/docs/campaigns',
  'platform/domains': 'https://comm.com/docs/platform/domains',
  'platform/domains/how-to-add-a-domain': 'https://comm.com/docs/platform/url-shortener#how-do-i-connect-an-existing-domain-to-the-url-shortener',
  'campaign/routing/simulate-routes': 'https://comm.com/docs/sms-marketing/routing/routing',
}
